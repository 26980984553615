var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "pb-50"
  }, [_c('h5', [_vm._v(" Filters ")])]), _c('b-card-body', [_c('b-row', [_c('b-col', {
    staticClass: "mb-md-0 mb-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-for": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "title",
      "value": _vm.searchFields.name
    },
    on: {
      "input": function input($event) {
        return _vm.inputChange($event, 'name');
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-md-0 mb-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Job Title",
      "label-for": "job_title"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "job_title",
      "value": _vm.searchFields.job_title
    },
    on: {
      "input": function input($event) {
        return _vm.inputChange($event, 'job_title');
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-md-0 mb-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Mobile",
      "label-for": "mobile"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "mobile",
      "type": "number",
      "value": _vm.searchFields.mobile
    },
    on: {
      "input": function input($event) {
        return _vm.inputChange($event, 'mobile');
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-md-0 mb-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "email"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "email",
      "value": _vm.searchFields.email,
      "type": "email"
    },
    on: {
      "input": function input($event) {
        return _vm.inputChange($event, 'email');
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mb-md-0 mb-2",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('label', [_vm._v("Status")]), _c('v-select', {
    staticClass: "w-100",
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.statusList,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "value"
    },
    model: {
      value: _vm.searchFields.status,
      callback: function callback($$v) {
        _vm.$set(_vm.searchFields, "status", $$v);
      },
      expression: "searchFields.status"
    }
  })], 1)], 1)], 1)], 1), _c('TableIndex', {
    attrs: {
      "title": _vm.title,
      "resource-name": _vm.resourceName,
      "singular-name": _vm.singularName,
      "filter-fields": _vm.searchFields
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }