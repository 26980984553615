<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="title"
                :value="searchFields.name"
                @input="inputChange($event, 'name')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Job Title"
              label-for="job_title"
            >
              <b-form-input
                id="job_title"
                :value="searchFields.job_title"
                @input="inputChange($event, 'job_title')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Mobile"
              label-for="mobile"
            >
              <b-form-input
                id="mobile"
                type="number"
                :value="searchFields.mobile"
                @input="inputChange($event, 'mobile')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                :value="searchFields.email"
                type="email"
                @input="inputChange($event, 'email')"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Status</label>
            <v-select
              v-model="searchFields.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusList"
              :reduce="item => item.id"
              label="value"
              class="w-100"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <TableIndex
      :title="title"
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      title: 'teams',
      resourceName: 'teams',
      singularName: 'team',
      searchFields: {
        name: null,
        job_title: null,
        mobile: null,
        email: null,
        status: null,
      },
      statusList: [{
        id: 'active',
        value: 'Active',
      }, {
        id: 'inactive',
        value: 'Inactive',
      }],
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
  },
}
</script>
